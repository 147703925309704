.container {
  display: flex;
  flex-direction: column;
  padding: 17px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: transparent;
  transition: all 0.3s ease, padding 0.8s linear;
}

.container:hover {
  background-color: var(--hover-background);
}

label {
  font-family: var(--font-heavy);
  font-size: 15px;
  color: var(--black);
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.02em;
}

.numberInput {
  font-family: var(--font-heavy);
  font-size: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--purple);
}
.numberInput::placeholder {
  color: var(--text-light);
}

.error {
  background-color: var(--danger-form);
}

.container.compact {
  padding: 10px 0px 0px 0px;
  display: flex;
  margin-bottom: 0;

  & > input {
    width: 100%;
  }

  & > label {
    font-size: 10px;
    margin-bottom: 0;
  }
}
