h1.tech {
  color: var(--tech);
  padding: 0 17px;
  font-family: var(--font-heavy);
  font-size: 26px !important;
}

h1.tech:after {
  background-color: var(--text-light);
  content: " ";
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 12px;
}

.musclesImg {
  max-width: 100%;
}

.saveButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-heavy);
  font-size: 15px;
  background-color: var(--success);
  color: #fff;
  padding: 5px 24px;
  min-height: 50px;
  border-radius: 8px;
  text-transform: uppercase;
}

.saveButton:hover {
  background-color: var(--success-light);
}
