.container {
  display: flex;
  flex-direction: column;
  padding: 17px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: transparent;
}

label {
  font-family: var(--font-heavy);
  font-size: 15px;
  color: var(--black);
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.02em;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.option {
  font-family: var(--font-heavy);
  height: fit-content;
  text-transform: uppercase;
  text-align: left;
  color: var(--text);
  border-width: 1px;
  border-style: solid;
  border-color: var(--text-light);
  border-radius: 8px;
  padding: 21px;
  margin-top: 12px;
  margin-right: 12px;
  min-width: 36px;
  cursor: pointer;
  transition: all 0.3s ease, padding 0.8s linear;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  :hover {
    border-color: var(--text);
  }
}

.checked {
  color: var(--success);
  border-color: var(--success);
}

.error {
  background-color: var(--danger-form);
}

.column {
  flex-direction: column;
}

.compact {
  padding: 10px 15px;
}
