h1.psico {
  color: var(--mais-vida);
  padding: 0 17px;
  font-family: var(--font-heavy);
  font-size: 26px !important;
}

h1.psico:after {
  background-color: var(--text-light);
  content: " ";
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 12px;
}

h3.psico {
  color: var(--mais-vida);
  padding: 0 17px;
  font-family: var(--font-medium);
  font-size: 16px;
}

h6.psico {
  color: var(--text);
  font-size: 12px;
  padding: 0 17px;
}
