.filesContainer {
  padding: 0 17px;
  font-family: var(--font-medium);
  font-size: 14px;
  letter-spacing: -0.01em;
  line-height: 16px;
  text-align: flex-start;
  color: var(--text);
  margin-bottom: 50px;
}

.filesLabel {
  font-family: var(--font-heavy);
  width: 100%;
  font-size: 15px;
  color: var(--black);
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.02em;
  margin-bottom: 15px;
}

.dragContainer {
  height: 296px;
  background-color: var(--card-background);
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(112, 112, 112, 0.34);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
}

.dragContainer:hover {
  opacity: 0.48;
}

.filesImage {
  margin-bottom: 13px;
}

.filesList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filesItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 13px;
  margin-right: 37px;
  min-width: 120px;
  cursor: pointer;
}

.filesCheck {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--text);
  background-color: transparent;
  margin-right: 11px;
}

.filesChecked {
  border-width: 0;
  background-color: var(--success);
}
