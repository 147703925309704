.inputContainer {
  display: flex;
  flex-direction: column;
  padding: 17px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: transparent;
  transition: all 0.3s ease;
}

.inputContainer:hover {
  background-color: var(--hover-background);
}

[contentEditable="true"] {
  font-family: var(--font-heavy);
  font-size: 16px;
  letter-spacing: -0.01em;
  line-height: 16px;
  text-align: left;
  color: var(--purple);
  margin-top: 7px;

  & > * {
    font-family: var(--font-heavy);
    font-size: 16px;
    letter-spacing: -0.01em;
    line-height: 16px;
    text-align: left;
    color: var(--purple);
    margin-top: 7px;
  }
}

[contentEditable="true"]:empty:not(:focus):before {
  content: attr(data-placeholder);
  font-family: var(--font-medium);
  color: var(--text-light);
}
